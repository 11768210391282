// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-claims-jsx": () => import("./../../../src/pages/claims.jsx" /* webpackChunkName: "component---src-pages-claims-jsx" */),
  "component---src-pages-commercial-jsx": () => import("./../../../src/pages/commercial.jsx" /* webpackChunkName: "component---src-pages-commercial-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-disclosures-jsx": () => import("./../../../src/pages/disclosures.jsx" /* webpackChunkName: "component---src-pages-disclosures-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-personal-jsx": () => import("./../../../src/pages/personal.jsx" /* webpackChunkName: "component---src-pages-personal-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-resources-jsx": () => import("./../../../src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */),
  "component---src-templates-post-template-jsx": () => import("./../../../src/templates/post-template.jsx" /* webpackChunkName: "component---src-templates-post-template-jsx" */),
  "component---src-templates-services-template-jsx": () => import("./../../../src/templates/services-template.jsx" /* webpackChunkName: "component---src-templates-services-template-jsx" */)
}

